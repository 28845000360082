.carousel {
  /* Adjust the width as needed */
  max-width: 100%;
  /* Center the carousel horizontally on the page */
  margin: 0 auto;
}

/* Style the active indicator dot */
.carousel-indicators .active {
  /* Set the background color for active indicator */
  background-color: black;
}

/* In your CSS file, add the following styles to hide the default indicators */
.carousel .carousel-indicators {
  display: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* Change the arrow color to black */
  background-color: black;
  border-radius: 20px;
}

.carousel-item {
  position: relative;
  display: none;
  width: 100%;
  transition: transform 0.6s ease-in-out;
  align-items: center;
}

.carousel-item img {
  border-radius: 5px !important;
  width: 100%;
  align-items: center;
}

/* Add these styles to your CSS file */
.horizontal-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* Adjust the margin as needed */
}

.horizontal-indicator {
  width: 10px;
  /* Adjust the width of the indicator */
  height: 2px;
  /* Adjust the height of the indicator */
  background-color: #000;
  /* Adjust the indicator color */
  margin: 0 5px;
  /* Adjust the margin between indicators */
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Optional: Add a transition effect */
}

.horizontal-indicator.active {
  background-color: #00f;
  /* Adjust the active indicator color */
}

@media (max-width: 450px) {
  .carousel {
    max-width: 100%;
  }
}

.thumbnail {
  width: 100px;
  /* filter: brightness(0.7);  */
}

.thumbnail-navigation {
  font-size: 36px;
  margin: 0 10px;
}

.thumbnail-highlighted {
  /* filter: brightness(1.3);  */
}

.horizontal-thumbnails {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail-navigation-left,
.thumbnail-navigation-right {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
}

@media (max-width: 600px) and (min-width: 501px) {
  .thumbnail {
    width: 60px;
  }
}

@media (max-width: 500px) and (min-width: 301px) {
  .thumbnail {
    width: 50px;
  }

  .thumbnail-navigation {
    font-size: 20px;
    margin: 0 5px;
  }
}

@media (max-width: 350px) {
  .thumbnail {
    width: 35px;
  }

  .thumbnail-navigation {
    font-size: 20px;
    margin: 0 5px;
  }
}

@media (max-width: 280px) {
  .carousel-item {
    margin-top: 10px;
  }
}

.carousel-controls{
  margin: 10px;
}