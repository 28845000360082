p3 {
  color: black;
}

.prodetails {
  padding: 20px !important;
  border-radius: 10px;
}

.row {
  /* display: flex; */
  align-items: center;
}

.profont {
  font-family: 'Poppins', sans-serif !important;
}

.fullcont {
  height: 90vh;
}

h4 {
  color: black;
}

.butpro {
  background-color: aqua;
}

.coverimg {
  border-radius: 0px 0px 20px 20px;
}

@media(max-width:700px) {
  .cardz {
    margin-top: 50px !important;
  }

  .prodetails {
    padding: 10px !important;
    border-radius: 10px;
  }

  .coverimg {
    border-radius: 0px 0px 10px 10px;
  }
}