*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato' ,sans-serif;
}
.waka-online {
    font-size: 16px;
    justify-content: center;
    align-items: center;
    display: flex;

  }
  .first-letter {
    font-size: 24px;
  }
.pri_subheading{
    padding-left: 50px;
    font-size: 13px;
    font-weight: bold;
}

.pri_para{
    padding-left: 50px;
    font-size: 13px;
    padding-bottom: 0px;
}

ul.pri_ul {
    list-style: none;
    margin-left: 30px;
    font-size: 13px;
  }
  
  ul li.pri_li:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 8px solid #b91d1d; /* Change the color as desired */
    margin-right: 20px;
    transform: rotate(90deg);
    
  }