* {
  box-sizing: border-box;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 35);
}

.outer {
  /* display: flex; */
  justify-content: center;
  flex-direction: column;
}

.outer-register {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

h2 {
  color: white;
}

.inner {
  /* display: flex;
  flex-direction: column; */
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 200px; */
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  padding: 30px 40px 40px 40px;
  border-radius: 15px;
  transition: all 0.2s;
  text-align: center;
  justify-content: center;
  /* z-index: 1; */
  position: relative;
  margin-top: 35vh;
}

.middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  position: relative;
}

.outer .form-control:focus {
  border-color: #c9302c;
  box-shadow: none;
}

.outer h2 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: white;
}

h2.headertekst {
  text-align: center;
}

.custom-control-label {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  color: black;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #ffffff;
}

.btn {
  width: 100%;
}

label {
  color: white;
}

p {
  color: #ffffff;
}

.forgot-password {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #acacac;
  margin: 0;
}

.forgot-password a {
  color: rgb(255, 255, 255);
}

.dropdown-toggle {
  white-space: nowrap;
  background-color: white !important;
  color: #ff0800 !important;
}

.dropdown-menu.show {
  display: block;
  width: 100%;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.SSO-Google,
.SSO-Facebook,
.SSO-Amazon,
.SSO-Apple {
  width: 100%;
  /* Set a fixed width for all buttons */
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-left: -4px;
  align-items: center !important;
  background-color: #ffffff;
  color: Black;
  cursor: pointer;
  border-radius: 8px;
  border-width: 2px;
  border: 2px solid #e3e3e3;
  font-size: 16px;
  font-weight: 300;
  border-color: rgb(208, 208, 208);
}

.SSO-Google {
  margin-top: 20px;
}

.SSO-Google:hover {
  background-color: #b83b34;
  color: white;
}

.SSO-Facebook:hover {
  background-color: #557fd9;
  color: white;
}

.SSO-Amazon:hover {
  background-color: #d68a18;
  color: white;
}

.SSO-Apple:hover {
  background-color: rgb(55, 55, 55);
  color: white;
}

.social {
  width: 30px;
  height: 30px;
}

.dropdown-item:checked {
  background-color: red !important;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  align-items: flex-start;
  /* Align buttons and content to the left */
  margin-left: 10px;
  font-family: 'Lato', sans-serif !important;
}

.SSO-Google img,
.SSO-Facebook img,
.SSO-Amazon img,
.SSO-Apple img {
  width: 1em;
  height: auto;
  margin-right: 5px;
  /* Adjust the margin as needed to separate image and text */
  margin-left: 25%;
}

.waka_logo img {
  width: 20%;
  height: auto;
  border-radius: 20px;
  margin-bottom: 5%;
}

.button-text {
  margin-left: 1vw;
}

@media screen and (max-width: 480px) {
  .inner {
    width: 90%;
    margin-top: 70vh;
  }

  .SSO-Google,
  .SSO-Facebook,
  .SSO-Amazon,
  .SSO-Apple {
    font-size: 12px;
    margin-left: -5px;
  }

  .background {
    background-image: url('https://images.pexels.com/photos/627680/pexels-photo-627680.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    /* Replace with the path to your image */
    background-size: cover;
    background-position: center top;
    /* Position the image at the top center */
    height: 30vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
  }

  .chat_texts {
    background-image: url('../img/backgroundmobi.jpg');
    height: 20vh;
  }
}


@media screen and (max-width: 1024px) and (min-width: 1000px) {
  .inner {
    margin-top: 45vh;
    width: 40%;
  }
}


@media screen and (max-width: 800px) {
  .inner {
    margin-top: 55vh !important;
  }
}

@media screen and (max-width: 600px) {
  .inner {
    margin-top: 70vh !important;
  }
}

@media screen and (max-width: 430px) {
  .inner {
    margin-top: 60vh !important;
  }
}

@media screen and (max-width: 360px) {
  .inner {
    margin-top: 70vh !important;
  }
}

@media screen and (max-width: 280px) {
  .inner {
    margin: auto !important;
    width: 100% !important;
    margin-top: 70vh !important;
  }

  .background {
    background-image: hidden;
  }

  .SSO-Google,
  .SSO-Facebook,
  .SSO-Amazon,
  .SSO-Apple {
    margin-left: -26px;
    width: 120%;
    font-size: 12px;
  }

  .social {
    width: 18px;
    height: 18px;
  }
}

.chat_texts {
  background-image: url('../img/background.jpg');
  /* Replace with the path to your image */
  background-size: cover;
  /* Adjust to cover the container */
  background-position: center top;
  /* Position the image at the top center */
  height: 50vh;
  /* Set the height to 50% of the viewport height (top half) */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
  /* margin-top: -140px; */
}

@media screen and (max-width: 1024px) {
  .chat_texts {
    background-image: url('../img/backgroundmobi.jpg');
    height: 20vh;
  }
}