.table-container {
  overflow-x: auto !important;
  /* Enable horizontal scroll when needed */
}

.table {
  width: 100%;
  /* Adjust the width as needed */
  overflow-x: auto !important;
}

.lastline {
  overflow-x: auto;
}