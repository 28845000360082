.offer-details {
  margin-top: 100px;
}

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: left;
}

td {
  text-align: left;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
  text-align: left;
}

table {
  caption-side: bottom;
  border-collapse: var(--bs-text-opacity);
}

.btn-success {
  /* margin-right: 20px; */
  color: #fff;
  background-color: #1b9b5f !important;
  border-color: #198754 !important;
}

.badge-success {
  color: #198754;
  background-color: #b9fffc;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-danger {
  color: #d10000;
  background-color: #ffe2e5;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-warning {
  color: #ffa800;
  background-color: #fff4de;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-primary {
  color: #8950fc;
  background-color: #eee5ff;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.col-sm-2 {
  text-align: left;
  flex: 0 0 auto;
  width: 20%;
  padding-left: 10px;
}

.flex {
  width: 250px;
  display: inline-flex;
}

@media screen and (max-width: 500px) {
  .flex {
    width: 90px;
    display: inline-block;
  }

  .btn-success {
    margin-bottom: 10px;
  }
  
  table {
    margin-left: -25px !important;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 50%;
    padding-left: 0;
  }

  .col-3 {
    width: 100%;
  }

  .row {
    width: 100%;
    display: inline-block;
  }
}
.request-vehicle-box{
  margin-bottom: 150px !important;
  padding-top: 100px;
  padding-bottom: 100px;
}