@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

details {
  color: #000000;
  font-size: 1.3rem;
}

summary {
  padding: 0.5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  transition: height 1s ease;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  content: '\002B';
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: 0.5em;
}

details[open] summary:after {
  content: '\00D7';
}

details[open] div {
  padding: 0.5em 1em;
}

.answer {
  font-size: 0.6em;
  margin-left: 20px;
}

.faq-heading {
  padding-top: 15px;
}

.faq-section {
  padding-left: 40px;
  padding-right: 100px;
  padding-bottom: 15px;
  justify-content: center;
}

.summary {
  font-size: 0.7em;
}
