/*desktops - higher resolution*/
@media (min-width: 1281px) {
    #mul_img {
        width: 80%;
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    #mul_img {
        width: 80%;
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
    #mul_img {
        width: 100%;
    }

    .prefButton {
        display: flex;
        justify-content: center !important;
        margin-top: 10px !important;
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #mul_img {
        width: 100%;
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
    #mul_img {
        width: 100%;
        padding-right: 10px;
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
    #mul_img {
        width: 100%;
        padding-right: 10px;
    }
}

@media (max-width: 600px) {
    .prefButton {
        display: flex;
        justify-content: center !important;
        margin-top: 10px !important;
    }
}