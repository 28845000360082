* {
  box-sizing: border-box;
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
}

h3 {
  color: rgb(0, 0, 0);
}

h2 {
  margin-bottom: 20px;
}

.container {
  width: 50%;
  margin: auto;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

dd,
dt {
  line-height: 1.42857143;
}

dd {
  color: rgb(131, 131, 131);
}

.outer h2 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: rgb(0, 0, 0);
}

.custom-control-label {
  font-weight: 400;
}

.btn {
  width: 30%;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
}

label {
  color: rgb(0, 0, 0);
  font-size: 16px;
}

p {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 280px) {
  .offers {
    width: 100% !important;
    overflow: hidden;
  }
}