@media (max-width: 280px) {
    .dropdown-toggle {
        width: 100% !important;
        /* Adjust the width as per your preference */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .red {
        width: 100% !important;
        padding: 0px !important;
    }

    .two {
        height: 40vh !important;
    }
}

@media (min-width: 550px) {
    .step4desc {
        margin-left: 10px;
    }
}