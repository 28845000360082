/* Add this in your CSS */
.wider-modal-dialog-box {
  width: 1000px;
  /* Adjust the width as needed */
  max-width: 100%;
  /* Ensure the modal doesn't exceed the viewport width */
}

/* Media query for maintaining mobile view */
@media (max-width: 767px) {
  .wider-modal-dialog-box {
    width: auto;
    /* Revert to automatic width for mobile view */
    max-width: 100%;
    /* Allow the modal to take full width */
  }
}

/* Media query for maintaining mobile view */
@media (max-width: 280px) {
  .red {
    width: 100% !important;
  }
}