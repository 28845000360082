.chatContain {
  padding: 30px !important;
  height: 100vh;
}

.chatSelect {
  margin: 0px 0px 15px 0px;
}

.chatSelectButton {

  color: maroon !important;
  width: 100px;
  border: 1px solid maroon !important;
}

.chatSelectButton:hover {
  background-color: maroon !important;
  color: white !important;
}

.persons {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;
}

.theChat {
  overflow: scroll;
  max-height: 400px;
}

.time {
  color: rgb(207, 207, 207);
  font-size: 10px;
}

.time2 {
  color: rgb(109, 108, 108);
  font-size: 10px;
}

.chatCard {
  width: 600px;
}

@media (max-width: 950px) {
  .chatCard {
    width: 400px;
  }
}

@media (max-width: 1024px) and (min-width:951) {
  .chatCard {
    width: 600px !important;
  }
}

.msges {
  margin-bottom: 5px;
}