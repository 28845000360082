/* body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  background-repeat: no-repeat, repeat;
  background-attachment: fixed;
  background-size: cover;
} */

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.container {
  width: 100% !important;
  height: auto;
}

.column {
  float: left;
}

.left {
  width: 55%;
}

.right {
  width: 55%;
}


.btn1 {
  margin-bottom: 30px;
  color: brown;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.failed {
  color: #ff0000;
  text-align: center;
  width: 40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
}

.unavailable {
  color: #808080;
  text-align: center;
  width: 40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;

}

.passed {
  color: #2a8d4f;
  background-color: '#F6F5F4';
  text-align: center;
  width: 40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;

}

.detail-content {
  width: 60%;
}

.detail-content-description {
  width: 100%;
}

.det_head {
  /* margin-bottom: 0.5px; */
  margin-bottom: 0;
  margin-top: 10px;
}

.fb-share {
  justify-content: start;
}

.visitor-count {
  justify-content: end;
}

@media (max-width: 767.98px) {
  .visitor-count,
  .fb-share {
    justify-content: center !important;
  }
}

.details_fc {
  color: rgb(40, 6, 6);
  font-weight: 900;
}

/* rating part css */
.info_logo {
  width: 15px;
  height: 15px;
  padding: 1px;
  filter: invert(10%) sepia(40%) saturate(6358%) hue-rotate(232deg) brightness(114%) contrast(129%);
  cursor: pointer;
}

.rating_det {
  font-size: 12px;
}

.Rating {
  display: flex;
}

.Rating_Star {
  width: 20px;
  height: 20px;
  filter: invert(10%) sepia(40%) saturate(6358%) hue-rotate(232deg) brightness(114%) contrast(129%);
  padding: 2px;

}

.rating_para {
  color: #000000;
  font-size: 12px;
}

.rating_h {
  font-size: 14px;
}

.no_rating {
  padding-bottom: 20px;
}

.rating_h1 {
  color: #00008B;
  margin-top: 20px;

}

.rating_clean_car {
  margin-top: 15px;
  padding-top: 15px;
  border: 1px solid f0f6fb;
  background-color: #f0f6fb;
  border-radius: 12px;
  display: flex;
  /* height: 80px; */
  padding-bottom: 15px;
}

.rating_wrong_img {
  width: 20px;
  height: 20px;
  margin: 10px;
  display: center;
  filter: invert(15%) sepia(98%) saturate(6831%) hue-rotate(359deg) brightness(103%) contrast(108%);
}

.rating_correct_img {
  width: 20px;
  height: 20px;
  margin: 10px;
  display: center;
  filter: invert(61%) sepia(75%) saturate(525%) hue-rotate(71deg) brightness(92%) contrast(89%);
}

.rating_clean_car_para {
  margin-top: 8px;
}

.background-row {
  margin-bottom: 20px;
}

.vehi-data {
  padding-top: 100px;
  padding-bottom: 100px;
}

.vehi-detail {
  background-color: #b1ebff;
  color: rgb(27, 37, 151);
  border-radius: 5px;
}

.vehi-detail-row {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 1281px) {
  .carousel-item img {
    width: 100%;
    max-height: 800px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .carousel-item img {
    width: 100%;
    max-height: 800px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-item img {
    max-width: 100%;
    max-height: 500px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .carousel-item img {
    max-width: 100%;
    max-height: 500px;
  }

}

@media (min-width: 481px) and (max-width: 767px) {
  .carousel-item img {
    max-width: 100%;
    max-height: 300px;
  }

  .background-row {
    margin-bottom: 25px;
  }

  .vehicle-table {
    padding-left: 20px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-item img {
    max-width: 100%;
    max-height: 300px;

  }

  .background-row {
    margin-bottom: 20px;
  }

  .vehicle-table {
    padding-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }

  .row {
    column-gap: 0.1rem;
  }
}

.popover-text {
  color: #000000;
  text-align: center;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 12px;
}

.image-overlay-container:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.carousel-item:hover .carousel-delete-btn {
  opacity: 1;
}

.carousel-item:hover .carousel-upload-btn {
  opacity: 1;
}

.carousel-delete-btn {
  font-size: 44px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay-container:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.carousel-item:hover .carousel-delete-btn {
  opacity: 1;
}

.carousel-upload-btn {
  font-size: 44px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.no-img-txt {
  color: #111;
}

.no-img-btn {
  font-size: 40px;
  color: #111;
}

.print-only {
  display: none;
}

@media print {
  body::before {
    content: url("../../assets/waka-logo/waka-pdflogo-light-bg.png");
    display: flex;
    align-items: flex-end;
    top: 0;
    margin-right: 0px !important;
    background-color: #ffffff;
    padding: 0px;
  }

  .print-only {
    display: block;
  }

  .web-address {
    font-size: 12pt;
    color: #333;
    font-weight: bold;
  }

  .align-right {
    text-align: right;
    background-color: #f0f6fb;
    top: 0;
    left: 50;
    right: 0;
    padding: 0px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  body::before::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
  }

  .avoid-breakInside {
    break-inside: avoid !important;    
  }

  .exclude-from-print {
    display: none;
  }

  @-moz-document url-prefix() {
    .shrink-table {
      break-inside: avoid !important;
      display: table;
    }
  }

  .makeCol {
    column-count: 2;
    column-gap: 20px;
  }

  .odometergraph {
    transform: scale(0.8);
    transform-origin: top left;
    margin: 0 !important;
  }
}

@page {
  size: auto;
  margin: 9mm;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}

.logoandtopic {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.logo-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  margin-right: 10px;
  /* Adjust the spacing between the image and text */
}

.logo-image {
  width: 70px;
  /* Adjust the width of the image as needed */
  height: 70px;
  /* Adjust the height of the image as needed */
  margin-right: 10px;
  /* Adjust the spacing between the image and text */
}

@media (max-width: 450px) {
  .content-container {
    flex-direction: column;
    /* Reverse order for smaller screens */
  }

  .details {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  .customListGroup {
    padding-left: 7px;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 5px;
  }

  .editBotton {
    height: 20px !important;
    width: 20px !important;
  }
}

.editIcon {
  display: flex;
  justify-content: flex-end;
}

.highL {
  border: 2px solid rgb(162, 32, 32) !important;
  border-radius: 50px !important;
  margin: 5px;
  margin-bottom: 10px;
  padding: 2px 10px 2px 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.basics {
  padding: 2px !important;
}

@media (max-width: 360px) {
  .highL {
    margin: 2px !important;
    margin-bottom: 5px !important;
    padding: 1px 5px 1px 5px;
  }
}

@media (max-width: 912px) {
  .details {
    flex-wrap: wrap;
    /* Allow items to wrap onto the next line */
  }

  .details>div {
    flex-basis: 50%;
    /* Two items per row on smaller screens */
  }
}

.reducepd1 {
  padding: 20px !important;
}

.topdetails01 {
  margin-top: 10px;
}

.graph {
  height: 300px !important;
  /* Adjust the height to your preferred value */
  overflow: hidden;
}

.details_fc {
  margin-top: 15px;
}

.headin {
  margin-top: 10px !important;
}

.numberPlate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.custom-p1 {
  font-family: 'Nova Square', sans-serif;
  font-size: 50px;
  border: 4px solid #000;
  font-weight: 800;
  padding: 5px 60px 5px 60px;
  border-radius: 10px;
}

@media (min-width: 750px) and (max-width: 912px) {
  .custom-p1 {
    font-size: 25px;
    border: 3px solid #000;
  }

  .engineNo {
    font-size: 12px;
  }
}