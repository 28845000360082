.navbar {
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(205, 197, 197, 0.21);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
}

.logo-img {
  width: 160px;
  height: auto;
}

.navbar-logo:hover {
  color: rgb(255, 0, 0);
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-top: 5px;
  height: 100%;
  font-size: 15px;
}

.nav-links:hover {
  border-bottom: 3px solid #eb0808;
  transition: all 0.2s ease-out;
  color: rgb(236, 17, 17);
}

.nav-links-active {
  color: rgb(236, 17, 17);
  border-bottom: 3px solid #eb0808;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-top: 5px;
  height: 100%;
  font-size: 15px;
}

.nav-links-active:hover {
  border-bottom: 3px solid #eb0808;
  transition: all 0.2s ease-out;
  color: rgb(236, 17, 17);
}

.fa-bars {
  color: #000000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.fa-car {
  color: #eb0808;
}

.btn {
  width: 100%;
  background-color: #c9302c !important;
}

.rounded-circle {
  cursor: pointer;
}

.logIn {
  padding-left: 10px;
}

.dropdown {
  position: relative;
  /* display: inline-block; */
}

.dropdown-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 12px 22px 15px;
  font-size: 15px;
  line-height: 0.4;
  text-decoration: none;
  display: block;
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ccc;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 960px) {
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    left: -200%;
  }

  .navbar-container {
    border: none;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: fit-content !important;
  }

  .nav-links {
    text-align: center;
    padding-top: 3rem;
    padding-left: 6rem;
    padding: 1.5rem;
    width: 90%;
    display: table;
    color: black;
  }

  .nav-links:hover {
    background-color: #eb0808;
    color: #ffffff;
    border-radius: 3;
    background-color: #fff;
    color: #eb0808;
    transition: 250ms;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 60px;
    transform: translate(25%, 50%);
  }

  .user-profile {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-25%, -5%);
  }

  .menu-icon {
    display: block;
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: left;
    left: -160px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-bars {
    float: right;
  }

  .fa-times {
    color: #000000;
    font-size: 2rem;
  }

  .fa-car {
    color: #eb0808;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: #eb0808;
    color: #fff;
    padding: 14px 20px;
    border: none;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background-color: #fff;
    color: #eb0808;
    transition: 250ms;
  }
}

.user-avatar-md {
  width: 100%;
  max-width: 50px;
  /* or adjust this value as needed */
  height: auto;
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .mob_resp {
    display: none;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .mob_resp {
    display: none;
  }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1020px) {
  .mob_resp {
    display: none;
  }

}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1020px) and (orientation: landscape) {
  .mob_resp {
    display: none;
  }

}

@media (max-width: 768px) {
  .user-profile {
    flex-direction: row;
    /* Stack items vertically on small screens */
  }

  .user-profile li {
    margin-bottom: 10px;
    /* Add some space between the items */
  }
}

@media (max-width: 300px) {
  .menu-icon {
    left: -110px !important;
  }

  .logo-img {
    width: 90px !important;
    height: auto;
    margin-top: 5px;
    margin-left: -10px;
  }

  .user-profile {
    margin-left: 10px;
  }
}

@media (max-width: 380px) and (min-width:301px) {
  .menu-icon {
    left: -120px !important;
  }

  .logo-img {
    width: 110px !important;
    height: auto;
    margin-top: 5px;
  }
}

@media (max-width: 600px) and (min-width:500px) {
  .menu-icon {
    left: -250px !important;
  }
}

@media (max-width: 800px) and (min-width:600px) {
  .menu-icon {
    left: -320px !important;
  }

  .logo-img {
    margin-left: 20px;
  }
}

@media (max-width: 820px) and (min-width:801px) {
  .menu-icon {
    left: -350px !important;
  }

  .logo-img {
    margin-left: 20px;
  }
}