.offer-details {
  margin-top: 100px;
}

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: left;
}

td {
  text-align: left;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
  text-align: left;
}

table {
  caption-side: bottom;
  border-collapse: var(--bs-text-opacity);
  width: 100%;
}

#one-col {
  padding-top: 5px;
}

.btn-success {
  color: #fff;
  background-color: #1b9b5f !important;
  border-color: #198754 !important;
}

.badge-success {
  color: #198754;
  background-color: #b9fffc;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-danger {
  color: #d10000;
  background-color: #ffe2e5;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-warning {
  color: #ffa800;
  background-color: #fff4de;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.badge-primary {
  color: #8950fc;
  background-color: #eee5ff;
  width: 70px;
  height: 30px;
  vertical-align: middle;
  line-height: 20px;
}

.col-sm-2 {
  text-align: left;
  flex: 0 0 auto;
  width: 20%;
  padding-left: 10px;
}

.flex {
  width: 250px;
  display: inline-flex;
}

#search-button {
  margin-top: -2px;
}

@media screen and (max-width: 1920px) {
  #statusValue {
    margin-left: -90px;
  }

  #accept-btn {
    width: 120px !important;
    /* margin-left: -70px; */
  }

  #one-col {
    width: 150px !important;
  }

  #one-col-1 {
    padding-left: 60px !important;
  }

  #one-col-2 {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1360px) {
  #statusValue {
    margin-left: -50px;
  }

  #accept-btn {
    width: 120px !important;
    /* margin-left: -20px; */
  }

  #one-col {
    width: 150px !important;
  }

  #one-col-1 {
    padding-left: 60px !important;
  }

  #one-col-2 {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1024px) {
  #statusValue {
    margin-left: 45px;
  }

  #accept-btn {
    width: 120px !important;
    /* margin-left: 70px; */
  }

  #one-col {
    width: 150px !important;
  }

  #one-col-1 {
    padding-left: 60px !important;
  }

  #one-col-2 {
    padding-left: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  table {
    width: 100% !important;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media screen and (max-width: 560px) {
  #search-button {
    width: 120% !important;
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 500px) {
  #search-button {
    width: 100% !important;
  }

  .flex {
    width: 90px;
    display: inline-block;
  }

  .btn-success {
    margin-bottom: 10px;
  }

  table {
    width: 110% !important;
  }

  th {
    font-size: 12px !important;
  }

  td {
    font-size: 12px !important;
  }

  #accept-btn {
    width: 80px !important;
    /* margin-left: 40px; */
    font-size: 12px;
    /* background-color: #1b9b5f !important; */
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 50%;
    padding-left: 0;
  }

  .col-3 {
    width: 100%;
  }

  .row {
    width: 100%;
    display: inline-block;
  }

  #search-button {
    height: 50px !important;
  }

  .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  #reject-btn {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 360px) {
  table {
    width: 115% !important;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

}

@media screen and (max-width: 280px) {
  table {
    width: 125% !important;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .row {
    margin-top: -40px !important;
    margin-left: -20px !important;
    width: 125% !important;
  }
}

.received-offers-box {
  margin-bottom: 150px !important;
  padding-top: 100px;
  padding-bottom: 100px;

}