:root {
  --primary: #fff;
}

.btn {
  padding: 8px 15px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #c9302c;
  color: #c9302c;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: #c9302c;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

@media screen and (min-width: 950px) {
  .btn--print {
    margin-top: 1cm;
    float: right;
    max-width: 20% !important; 
    width: auto; /* Allow button to adjust its width */
  }
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.left-btn {
  float: left;
}

.right-btn {
  float: right;
}
.btn-size {
  width: 250px;
}



#crop-btn
{
  /* width: 80px; */
  height: 45px;
  margin-right: 20px;
  border: 1.5px solid #b11c1c;
  background-color: transparent;
  color: #b11c1c;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#crop-btn:hover {
  background-color: #b11c1c;
  color: #fff;
}