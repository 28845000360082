/* {
    box-sizing: border-box;
  }
   */
/* body {
  display: flex;
  font-weight: 400;
  font-family: poppins;
  background-repeat: no-repeat, repeat;
  background-size: cover;
} */

/* body, html, .App, #root, .outer {
    width: 100%;
    height: 100%;
  }
   */

h3 {
  color: rgb(0, 0, 0);
}

h2 {
  margin-bottom: 20px;
}

.container {
  /* margin-top: 170px !important; */
  width: 50%;
  margin: auto;
  /* background: rgb(255, 255, 255); */
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px); */

  /* padding: 40px 55px 45px 55px; */
  /* border-radius: 15px; */

  /* From https://css.glass */
  background: rgba(253, 248, 248, 0.09);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(205, 197, 197, 0.21);
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

dd,
dt {
  line-height: 1.42857143;
}

dd {
  color: rgb(131, 131, 131);
}

.stolen-failed {
  color: #ff0000;
  text-align: left;
  width:40%;
  font-size: 15px;
}

.stolen-unavailable {
  color: #808080;
  text-align: left;
  width:40%;
  font-size: 15px;
}

.stolen-passed {
  color: #2a8d4f;
  background-color: '#F6F5F4';
  text-align: left;
  width:40%;
  font-size: 15px;
}

.outer h2 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: rgb(0, 0, 0);
}

.custom-control-label {
  font-weight: 400;
}

input[type='checkbox'] {
  transform: scale(1.5);
}

.form-check {
  width: 285px;
  padding-left: 0 !important;
}

.form-check .form-check-input {
  float: right !important;
}

.form-check-input:checked {
  background-color: #c9302c;
  border-color: #c9302c;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
}

.form-check-label {
  color: rgb(0, 0, 0);
  font-size: 16px;
}

p {
  color: #ffffff;
}

@media screen and (max-width: 1920px) {
  .form-check {
    width: 330px;
  }
}

@media screen and (max-width: 1368px) {
  .form-check {
    width: 285px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }

  .form-check {
    width: 220px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 90%;
  }

  .form-check {
    width: 220px;
  }
}

@media screen and (max-width: 280px) {
  .form-check {
    width: 200px;
  }
}
