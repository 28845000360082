.contained {
  background-color: rgb(197, 43, 43) !important;
}

.buttonOncard {
  width: 100%;
  background-color: rgb(197, 43, 43) !important;
  color: white;
  margin: 5px 5px 10px 0px !important;
}

.vehicleImage {
  position: relative;
  width: auto;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 8px 5px 8px;
  border-radius: 8px !important;
}

@media (max-width: 280px) {
  .vehicleImage {
    width: 180px !important;
    height: 190px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 15px !important;
  }
}

@media (max-width: 320px) {
  .vehicleImage {
    width: 200px !important;
    height: 190px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 15px !important;
  }
}

@media (max-width: 950px) and (min-width: 700px) {
  .vehicleImage {
    width: 180px !important;
    height: 190px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 15px !important;
  }
}

@media (max-width: 399px) and (min-width: 360px) {
  .vehicleImage {
    width: 250px !important;
    height: 190px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 15px !important;
  }
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .vehicleImage {
    width: 250px !important;
    height: 190px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 15px !important;
  }
}