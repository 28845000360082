.text-cell {
  display: flex;
  width: 70%;
  margin: 0;
  margin: 5px 0px 5px 0px;
}

.part1 {
  flex: 1;
  background-color: rgb(40, 6, 6);
  padding: 2px 2px 2px 5px;
  color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align text to the left */
  justify-content: center;
  border: 2px solid rgb(40, 6, 6);
  border-radius: 5px 0px 0px 5px;
}

.part2 {
  flex: 1;
  background-color: transparent;
  padding: 2px 2px 2px 8px;
  color: rgb(40, 6, 6);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align text to the left */
  justify-content: center;
  border: 2px solid rgb(40, 6, 6);
  border-radius: 0px 5px 5px 0px;
}

@media (max-width: 500px) {
  .text-cell {
    width: 100%;
  }

  .vin {
    font-size: 10px;
  }

  .engineNo {
    font-size: 13px;
  }

  .assembly {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .vin {
    font-size: 12px;
  }
}

@media (min-width: 912px) and (max-width: 1000px) {
  .vin {
    font-size: 9px;
  }
}

@media (max-width: 320px) {
  .vin {
    font-size: 10px;
  }

  .engineNo {
    font-size: 13px;
  }

  .assembly {
    font-size: 12px;
  }
}

@media (max-width: 820px) and (min-width: 750px) {
  .vin {
    font-size: 9px;
  }

  .engineNo {
    font-size: 12px;
  }

  .assembly {
    font-size: 12px;
  }
}