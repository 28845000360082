.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.btn {
  background-color: #eb0808;
  border: none;
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
}

.btn:hover {
  background-color: #ffffff;
  color: #eb0808;
}

.footLink {
  color: #000000;
  font-weight: 400;
}

.footLink:hover {
  transition: all 0.2s ease-out;
  color: rgb(236, 17, 17);
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .btn {
    width: 100%;
    background-color: #eb0808;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

}


/* -----------new footer--------- */
.footer {
  /* position: sticky; */
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-top: 1px solid rgba(39, 37, 37, 0.21);
}

.footer-paragraph {
  color: #000000;
  font-size: 12px;

}

.footer-pages {
  text-decoration: none;
  color: #000000;

}

@media (min-width: 765px) {
  .footer-copyright {
    text-align: right;
  }
}