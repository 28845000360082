@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.top-nav-content {
  font-family: var(--main-font);
  font-weight: 500;
}

.card-icon-top {
  width: 50px;
  height: 50px;
}

.title-content {
  font-family: var(--main-font);
  color: #000000;
}

.paragraph-content {
  font-family: var(--main-font);
  color: var(--grey);
  font-size: 15px;
  line-height: 1.8;
}

.image-container {
  position: relative;
}

.img {
  max-width: 100% !important;
  /* Limit the maximum width of images to their container */
  height: auto;
  /* Maintain aspect ratio */
  display: block !important;
  /* Remove any extra space at the bottom of the image */
  margin-top: 0px;
  /* Center the image within its container */
}

/* Style for the container using CSS Grid */
.container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Divide the container into two columns */
  gap: 0px;
  /* Add some gap between the columns */
  width: 100%;
  /* Take up the full width of the viewport */
}

.container {
  margin-bottom: 5px !important;
  padding: 50px;
  width: 100%;
  /* Take up the full width of the viewport */
}

/* Style for the left section */
.left-section {
  background-color: #f0f0f0;
  /* Add a background color */
  padding: 0px;
  /* Add some padding for spacing */
}

/* Style for the right section */
.right-section {
  background-color: #e0e0e0;
  /* Add a background color */
  padding: 0px;
  /* Add some padding for spacing */
}

.foot {
  margin-bottom: 7vh;
}

@media only screen and (max-width: 280px) and (min-width: 270px) {
  .container {
    padding: 30px 30px 55px;
  }
}

@media screen and (max-width: 320px) and (min-width: 300px) {
  .container {
    margin-bottom: 5px !important;
    width: 100%;
    /* Take up the full width of the viewport */
  }
}

@media screen and (max-width: 530px) and (min-width: 360px) {
  .container1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Divide the container into two columns */
    gap: 0px;
    /* Add some gap between the columns */
    width: 100%;
    /* Take up the full width of the viewport */
  }

  .container {
    margin-bottom: 5px !important;
    padding: 30px 30px 55px;
    width: 100%;
    /* Take up the full width of the viewport */
  }

  .foot {
    margin-bottom: 10vh;
  }
}

.ground {
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.card {
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  display: grid;
  /* Use CSS Grid */
  grid-template-columns: 1fr 1fr;
  /* Divide into left and right columns */
  align-items: center;
}

.logo {
  width: 100px;
  margin: 10px;
  border-radius: 50px;
  border: 2px solid rgb(201, 200, 200);
}

.company-name {
  font-size: 2.0rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;

}

/* Additional styles for the "Featured Dealer" */
.featured-dealer {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Arial, sans-serif';
  font-weight: 900;
  color: #ffffff;
}

@media (max-width: 280px) {
  .card {
    position: absolute;
    top: 25%;
    padding: 5px;
    width: 100%;
  }

  .logo {
    width: 40px;
    margin: 2px;
    border-radius: 50px;
    border: 0.5px solid rgb(255, 255, 255);
  }

  .company-name,
  .featured-dealer {
    font-size: 10px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 500;
  }
}

@media (max-width: 320px) and (min-width: 300px) {
  .card {
    position: absolute;
    top: 30%;
    padding: 5px;
    width: 100%;
  }

  .logo {
    width: 40px;
    margin: 2px;
    border-radius: 50px;
    border: 0.5px solid rgb(255, 255, 255);
  }

  .company-name,
  .featured-dealer {
    font-size: 10px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 500;
  }
}

@media (max-width: 375px) and (min-width: 321px) {
  .card {
    position: absolute;
    top: 20%;
    padding: 5px;
    width: 100%;
  }

  .logo {
    width: 40px;
    margin: 2px;
    border-radius: 50px;
    border: 0.5px solid rgb(255, 255, 255);
  }

  .company-name,
  .featured-dealer {
    font-size: 10px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 500;
  }
}

@media (max-width: 400px) and (min-width: 351px) {
  .card {
    position: absolute;
    top: 20%;
    padding: 5px;
    width: 100%;
  }

  .logo {
    width: 40px;
    margin: 2px;
    border-radius: 50px;
    border: 0.5px solid rgb(255, 255, 255);
  }

  .company-name,
  .featured-dealer {
    font-size: 10px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 500;
  }
}

@media (max-width: 500px) and (min-width: 400px) {
  .card {
    position: absolute;
    top: 20%;
    padding: 10px;
    width: 100%;
  }

  .logo {
    width: 40px;
    margin: 2px;
    border-radius: 50px;
    border: 0.5px solid rgb(255, 255, 255);
  }

  .company-name,
  .featured-dealer {
    font-size: 10px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 500;
  }
}

@media (min-width: 501px) and (max-width: 1000px) {
  .card {
    position: absolute;
    top: 20%;
    width: 100%;
    padding: 10px;
  }

  .logo {
    width: 60px;
    margin: 2px;
  }

  .company-name,
  .featured-dealer {
    font-size: 15px;
    /* Adjust the font size for the company name */
  }

  .featured-dealer {
    font-weight: 600;
  }

}

@media (min-width: 1001px) and (max-width: 1050px) {
  .card {
    position: absolute;
    top: 12%;
    width: 100%;
    padding: 10px;
  }

  .logo {
    width: 70px;
    margin: 2px;
    border-radius: 50px;
  }

  .company-name,
  .featured-dealer {
    font-size: 20px;
    /* Adjust the font size for the company name */
  }
}

@media (max-width: 320px) {
  .card {
    top: 25%;
    width: 100%;
  }

  .logo {
    width: 30px;
    margin: 2px;
    border-radius: 50px;
  }

  .company-name,
  .featured-dealer {
    font-size: 8px;
    /* Adjust the font size for the company name */
  }
}

@media (max-width: 1280px) and (min-width: 1250px) {
  .card {
    top: 20%;
  }
}