.tc_first {
    padding-left: 50px;
}

.tc_list {
    color: #b91d1d;
    /* background-color: ; */
}

.tc_subheading {
    font-size: 13px;
    font-weight: bold;
    /* padding-left:50px; */
}

.tc_ol {
    padding-left: 10px;
}

.tc_li {
    padding-left: 0px;
    font-size: 13px;
    padding-bottom: 6px;
    color: black;
    padding-right: 30px;
    text-align: justify !important;
}

.tc_para {
    padding-left: 50px;
    font-size: 13px;
    padding-bottom: 0px;
}

.pri_para {
    padding-left: 0px !important;
    padding-right: 30px;
    text-align: justify !important;
}

@media screen and (max-width: 700px) {
    .tc_first {
        padding-left: 30px;
        text-align: justify;
    }
}