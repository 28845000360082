.ButAccount {
  width: 15vw !important;
}

@media (max-width: 600px) {
  .ButAccount {
    width: 30vw !important;
  }
}

@media (max-width: 320px) {
  .ButAccount {
    width: 40vw !important;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .vehicont {
    margin-top: 20px;
  }
}