* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;

}

html,
body {
  min-height: 100%;
  box-sizing: border-box;

}

.home,
.services,
.products,
.signup {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* -------------custom css------------ */

:root {
  --font-color: #000000;
  --dark-color: #000000;
  --white-color: #ffffff;
}

.m-spacing {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.p-spacing {
  padding-bottom: 4rem !important;

}

.label-color {
  color: var(--font-color);
}

.claim-topic-color {
  color: var(--font-color);
  text-align: center;
}

.upload-btn {
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.crop-btn {
  right: 0;
  float: right;
}

.file-upload-btn {
  background-color: #c9302c;
  padding: 8px 20px;
  border: 1px solid #c9302c;
  color: #fff;
  margin: 2px 5px;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;
}

.uploaded-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e9f0ff;
}

.upload-content {
  display: flex;
  align-items: center;
}

.fupload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #c9302c;
  height: 300px;
  width: 500px;
  cursor: pointer;
  border-radius: 5px;
}

/* .dropdown-item{
  padding:15px 5px 15px 20px;
  color:var(--dark-color);
} */


.dropdown-item:focus,
.dropdown-item:hover {
  /* color:$white-color; */
  /* background-color:$base-color; */
}


.dropdown-menu {
  box-shadow: 0px 5px 18px 5px rgba(0, 0, 0, 0.13);
  border: 0;
}

.passed-grp {
  color: #12f840;
  background-color: #e3e3e3;
}

.fa-caret-down {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.model-para {
  color: black;
}

/* ----------------chat screen------------- */
@media (max-width: 800px) {
  .u_name {
    /* display: none; */
  }

  .cht-avatar {
    right: 0;
    max-width: 100px;
    max-height: 50px;
  }

  #cht-send-btn {
    width: 40px;
    height: 40px;
  }

  #cht-send-icon {
    font-size: 16px;
  }
}




/*desktops - higher resolution*/
@media (min-width: 1281px) {
  .chat_typ {
    padding-left: 25px;
  }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .chat_typ {
    padding-left: 25px;
  }

}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
  .chat_typ {
    padding-left: 25px;
  }

}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .chat_typ {
    padding-left: 25px;
  }

}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .chat_typ {
    padding-left: 7px;
    font-size: 10px;
  }

}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .chat_typ {
    padding-left: 7px;
    font-size: small;
  }

}

.app {
  min-height: 100vh;
  position: relative;
}

/* Chat screen  */
.chat_text {
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* change the swal button in details page */
.request-button {
  background-color: #c9302c !important;
}

.request-button:hover {
  background-color: white !important;
  color: rgb(99, 99, 99) !important;
  border: 1px solid gray !important;
}

.cht-disable {
  background-color: #ffc4c2;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

/* ----claim form----- */
.form-control-textarea {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Make the app wrapper take at least the full viewport height */
}

.content {
  flex: 1;
  /* Allow the content to grow and occupy the remaining vertical space */
}